import CONFIG from '../config'
import { OLOG, isDemoAccount, postSentryError } from '../lib/helpers'
import { Query } from '../interfaces/Query'

const queryName = (query: Query) => {
  try {
    // @ts-ignore
    return query.query.definitions[0].name.value
  } catch {
    return 'unknown'
  }
}

export default async (accessToken: string, query: Query) => {
  OLOG(`querying ${queryName(query)}${query.variables ? ` with variables ${JSON.stringify(query.variables)}` : ''}...`)

  const headers: any =
    isDemoAccount(accessToken) || !accessToken
      ? {
          'content-type': 'application/json',
          'x-hasura-role': 'admin',
          'x-hasura-admin-secret': CONFIG.HASURA_GRAPHQL_ADMIN_SECRET,
        }
      : {
          'content-type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        }

  const body = JSON.stringify({
    query: query.query.loc?.source.body,
    variables: query.variables,
  })

  const start = Date.now()

  const response = await fetch(CONFIG.HASURA_API_URL!, {
    body,
    headers,
    method: 'POST',
  })

  const result = await response.json()

  if (result.errors) {
    const error = result.errors[0]
    const msg = error.message
    const verboseMsg = `${msg} ${error.extensions.path}`
    console.log(`${query.route} ERROR`, verboseMsg)
    if (!msg.includes('JWTExpired')) postSentryError(verboseMsg)
    throw Error(msg)
  } else {
    const data = query.route ? result.data[query.route] : result.data
    const route = query.route ? query.route : Object.keys(data)[0]
    const seconds = Math.ceil((Date.now() - start) / 1000)
    // if (seconds > CONFIG.LONG_QUERY_SECONDS_THRESHOLD) postSentryError('Long query', { route, seconds })
    OLOG(`${route} SUCCESS ${seconds}sec`)
    OLOG(data)
    return data
  }
}
