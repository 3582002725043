import CONFIG from '../config'

export default async (endpoint: string, method: string = 'GET', body?: any | undefined, formData?: any | undefined) => {
  const url = `${CONFIG.RADIMAL_API_URL}${endpoint}`
  const params: any = { method }
  // const start = Date.now()

  if (formData) {
    params.body = formData
  } else {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    params.headers = headers
    if (body) params.body = JSON.stringify(body)
  }

  try {
    const response = await fetch(url, params)
    const data = await response.json()
    // const seconds = Math.ceil((Date.now() - start) / 1000)
    // if (seconds >= CONFIG.LONG_QUERY_SECONDS_THRESHOLD) postSentryError('Long query', { route: endpoint, seconds })
    return data
  } catch (error: any) {
    console.log(`${endpoint.split('?')[0]} ERROR`)
    console.log(error)
    throw Error(error.message)
  }
}
