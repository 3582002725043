import gql from 'graphql-tag'

import { specialist_payments_insert_input } from '../../../types/globalTypes'

export const specialistPaymentsQuery = (enterpriseIds: number[]) => ({
  query: gql`
    query SpecialistPayments($enterpriseIds: [Int!]!) {
      specialist_payments(where: { specialist_payment_group: { enterprise_id: { _in: $enterpriseIds } } }) {
        id
        amount
        consultation_type_id
        is_exotic
        level
        stat_type_id
        specialist_payment_group {
          id
          enterprise_id
        }
      }
    }
  `,
  variables: { enterpriseIds },
  route: 'specialist_payments',
})

export const insertSpecialistPayment = (object: specialist_payments_insert_input) => ({
  query: gql`
    mutation InsertSpecialistPayment($object: specialist_payments_insert_input!) {
      insert_specialist_payments_one(object: $object) {
        id
      }
    }
  `,
  variables: { object },
  route: 'insert_specialist_payments_one',
})

export const updateSpecialistPaymentAmount = (id: number, amount: number) => ({
  query: gql`
    mutation UpdateSpecialistPaymentAmount($id: Int!, $amount: float8!) {
      update_specialist_payments_by_pk(pk_columns: { id: $id }, _set: { amount: $amount }) {
        id
      }
    }
  `,
  variables: { id, amount },
  route: 'update_specialist_payments_by_pk',
})
