import gql from 'graphql-tag'

import { predictions_normalized_insert_input } from '../../../types/globalTypes'
import { QueryName } from '../queryNames'

export const insertPredictionsQuery = (objects: predictions_normalized_insert_input[]) => ({
  query: gql`
    mutation InsertPrediction($objects: [predictions_normalized_insert_input!]!) {
      insert_predictions_normalized(objects: $objects) {
        returning {
          id
        }
      }
    }
  `,
  route: 'insert_predictions_normalized',
  variables: { objects },
})

export const deletePredictionQuery = (id: number) => ({
  query: gql`
    mutation DeletePrediction($id: Int!) {
      delete_predictions_normalized_by_pk(id: $id) {
        id
      }
    }
  `,
  route: 'delete_predictions_normalized_by_pk',
  variables: { id },
})

export const deletePredictionsQuery = (ids: number[]) => ({
  query: gql`
    mutation DeletePredictions($ids: [Int!]!) {
      delete_predictions_normalized(where: { id: { _in: $ids } }) {
        affected_rows
      }
    }
  `,
  route: 'delete_predictions_normalized',
  variables: { ids },
})

export const deleteVetPredictionsQuery = (medical_image_id: number) => ({
  query: gql`
    mutation DeleteVetPredictions($medical_image_id: Int!) {
      delete_predictions_normalized(where: { vet_id: { _is_null: false }, _and: { medical_images_id: { _eq: $medical_image_id } } }) {
        affected_rows
      }
    }
  `,
  route: 'delete_predictions_normalized',
  variables: { medical_image_id },
})

export const fetchSpecialistPredictionsQuery = (start: string, name = QueryName.SpecialistPredictions) => ({
  query: gql`
    query SpecialistPredictions($start: timestamptz!) {
      predictions_normalized(
        where: { type: { _eq: "specialist-medical_image" }, _and: { created_at: { _gt: $start } } }
        order_by: { created_at: desc }
      ) {
        created_at
        medical_image {
          id
          species
          case {
            patient {
              species
            }
          }
        }
        condition {
          id
          display_name
        }
        user {
          id
          display_name
        }
      }
    }
  `,
  route: 'predictions_normalized',
  variables: { start },
  name,
})

export const fetchConsultationPredictionsForOrganizationQuery = (enterprise_id: number) => ({
  query: gql`
    query ConsultationPredictionsForOrganization($enterprise_id: Int!) {
      predictions_normalized(
        where: {
          consultation: { case: { dicom_server: { organization: { enterprise_id: { _eq: $enterprise_id } } } } }
          type: { _eq: "specialist-consultation" }
          condition_id: { _is_null: false }
        }
        order_by: { created_at: desc }
      ) {
        condition {
          id
          display_name
        }
      }
    }
  `,
  route: 'predictions_normalized',
  variables: { enterprise_id },
})
