import { createSlice } from '@reduxjs/toolkit'

import callFlask from '../callFlask'
import callHasura from '../callHasura'
import { SpecialistPredictions_predictions_normalized } from '../graphQlQueries/types/SpecialistPredictions'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { predictions_normalized_insert_input } from '../../../types/globalTypes'
import { ConsultationPredictionsForOrganization_predictions_normalized } from '../graphQlQueries/types/ConsultationPredictionsForOrganization'

import {
  deletePredictionQuery,
  deletePredictionsQuery,
  deleteVetPredictionsQuery,
  fetchConsultationPredictionsForOrganizationQuery,
  fetchSpecialistPredictionsQuery,
  insertPredictionsQuery,
} from '../graphQlQueries/Prediction'

export interface PredictionsState {
  hasErrors: boolean
  loading: boolean
  specialistPredictions: SpecialistPredictions_predictions_normalized[]
  isQuerying: any
  consultationPredictionsForOrganization: ConsultationPredictionsForOrganization_predictions_normalized[]
  isCreatingPdf: boolean
}

const initialState: PredictionsState = {
  loading: false,
  hasErrors: false,
  specialistPredictions: [],
  consultationPredictionsForOrganization: [],
  isCreatingPdf: false,
  isQuerying: {},
}

const predictionsSlice = createSlice({
  name: 'predictions',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    setIsCreatingPdf: (state, { payload }: { payload: boolean }) => {
      state.isCreatingPdf = payload
    },

    fetchSpecialistPredictionsSuccess: (state, { payload }: { payload: SpecialistPredictions_predictions_normalized[] }) => {
      state.specialistPredictions = payload
    },

    fetchConsultationPredictionsForOrganizationSuccess: (
      state,
      { payload }: { payload: ConsultationPredictionsForOrganization_predictions_normalized[] }
    ) => {
      state.consultationPredictionsForOrganization = payload
    },
  },
})

export const {
  setLoading,
  networkingSuccess,
  networkingFailure,
  setIsCreatingPdf,
  fetchSpecialistPredictionsSuccess,
  fetchConsultationPredictionsForOrganizationSuccess,
} = predictionsSlice.actions

export const predictionsSelector = (state: any) => state.predictions

export default predictionsSlice.reducer

export function createPdfAction(caseId: number) {
  return async (dispatch: any) => {
    dispatch(setIsCreatingPdf(true))

    try {
      await callFlask('/create-ai-report', 'POST', { id: caseId })
      dispatch(setIsCreatingPdf(false))
    } catch (error) {
      dispatch(setIsCreatingPdf(false))
    }
  }
}

export function insertPredictionsAction(accessToken: string, objects: predictions_normalized_insert_input[], caseId?: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertPredictionsQuery(objects))
      if (caseId) dispatch(createPdfAction(caseId))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deletePredictionAction(accessToken: string, id: number, caseId?: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deletePredictionQuery(id))
      if (caseId) dispatch(createPdfAction(caseId))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deletePredictionsAction(accessToken: string, ids: number[]) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deletePredictionsQuery(ids))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function deleteVetPredictionsAction(accessToken: string, medicalImageId: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, deleteVetPredictionsQuery(medicalImageId))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchSpecialistPredictionsAction(accessToken: string, start: string) {
  return async (dispatch: any) => {
    const query = fetchSpecialistPredictionsQuery(start)
    dispatch(setLoading(query.name))

    try {
      const result: SpecialistPredictions_predictions_normalized[] = await callHasura(accessToken, query)

      dispatch(fetchSpecialistPredictionsSuccess(result))
      dispatch(networkingSuccess(query.name))
    } catch (error) {
      dispatch(networkingFailure(query.name))
    }
  }
}

export function fetchConsultationPredictionsForOrganizationAction(accessToken: string, enterpriseId: number) {
  return async (dispatch: any) => {
    try {
      const result: ConsultationPredictionsForOrganization_predictions_normalized[] = await callHasura(
        accessToken,
        fetchConsultationPredictionsForOrganizationQuery(enterpriseId)
      )
      dispatch(fetchConsultationPredictionsForOrganizationSuccess(result))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
