import { createSlice } from '@reduxjs/toolkit'

import callHasura from '../callHasura'
import { SpecialistPayments_specialist_payments } from '../graphQlQueries/types/SpecialistPayments'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { insertSpecialistPayment, specialistPaymentsQuery, updateSpecialistPaymentAmount } from '../graphQlQueries/SpecialistPayments'
import { specialist_payments_insert_input } from '../../../types/globalTypes'
import { NotificationId, setNotificationAction } from './notifications'

export interface SpecialistPaymentsState {
  isQuerying: any
  specialistPayments: SpecialistPayments_specialist_payments[]
}

const initialState: SpecialistPaymentsState = {
  isQuerying: {},
  specialistPayments: [],
}

const specialistPaymentsSlice = createSlice({
  name: 'specialistPayments',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    specialistPaymentsSuccess: (state, { payload }: { payload: SpecialistPayments_specialist_payments[] }) => {
      state.specialistPayments = payload
    },
  },
})

export const { setLoading, specialistPaymentsSuccess, networkingFailure, networkingSuccess } = specialistPaymentsSlice.actions

export const specialistPaymentsSelector = (state: any) => state.specialistPayments

export default specialistPaymentsSlice.reducer

export function fetchSpecialistPaymentsAction(accessToken: string, enterpriseIds: number[]) {
  return async (dispatch: any) => {
    try {
      const specialistPayments: SpecialistPayments_specialist_payments[] = await callHasura(
        accessToken,
        specialistPaymentsQuery(enterpriseIds)
      )
      dispatch(specialistPaymentsSuccess(specialistPayments))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertSpecialistPaymentAction(accessToken: string, object: specialist_payments_insert_input, enterpriseId: number) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, insertSpecialistPayment(object))
      await dispatch(fetchSpecialistPaymentsAction(accessToken, [enterpriseId]))
      dispatch(setNotificationAction(NotificationId.PayRateSaved))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateSpecialistPaymentAmountAction(accessToken: string, id: number, amount: number, enterpriseId: number) {
  return async (dispatch: any) => {
    try {
      await callHasura(accessToken, updateSpecialistPaymentAmount(id, amount))
      await dispatch(fetchSpecialistPaymentsAction(accessToken, [enterpriseId]))
      dispatch(setNotificationAction(NotificationId.PayRateSaved))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
