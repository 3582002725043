import gql from 'graphql-tag'

import { case_questions_insert_input, conditions_insert_input } from '../../../types/globalTypes'
import { QueryName } from '../queryNames'
import { CONDITION_FIELDS } from './fragments/conditionFields'

export const fetchConditionPredictionCountsQuery = (name = QueryName.ConditionPredictionCounts) => ({
  query: gql`
    query ConditionPredictionCounts {
      conditions(order_by: { display_name: asc }) {
        id

        active_learning_cat_priority
        active_learning_dog_priority
        train_and_deploy_cat
        train_and_deploy_dog
        cat_model_last_trained_at
        dog_model_last_trained_at

        display_name
        ml_name

        ml_models(order_by: { id: desc }) {
          created_at
          species
        }

        cat_predictions_normalizeds_aggregate: predictions_normalizeds_aggregate(
          where: {
            consultation: { consultation_type_id: { _eq: 1 }, case: { patient: { species: { _eq: "cat" } } } }
            consultation_id: { _is_null: false }
          }
        ) {
          aggregate {
            count
          }
        }

        dog_predictions_normalizeds_aggregate: predictions_normalizeds_aggregate(
          where: {
            consultation: { consultation_type_id: { _eq: 1 }, case: { patient: { species: { _eq: "dog" } } } }
            consultation_id: { _is_null: false }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
  route: 'conditions',
  name,
})

export const insertConditionQuery = (object: conditions_insert_input) => ({
  query: gql`
    mutation InsertCondition($object: conditions_insert_input!) {
      insert_conditions_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_conditions_one',
  variables: { object },
})

export const insertCaseQuestionQuery = (object: case_questions_insert_input) => ({
  query: gql`
    mutation InsertCaseQuestion($object: case_questions_insert_input!) {
      insert_case_questions_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_case_questions_one',
  variables: { object },
})

export const fetchConditionCategoriesQuery = () => ({
  query: gql`
    query ConditionCategories {
      condition_categories(order_by: { order_index: asc }) {
        id
        order_index
        display_name
        region
      }
    }
  `,
  route: 'condition_categories',
})

export const fetchConditionsQuery = () => ({
  query: gql`
    ${CONDITION_FIELDS}
    query Conditions {
      conditions(where: { deprecated: { _eq: false } }) {
        ...condition_fields
      }
    }
  `,
  route: 'conditions',
})

export const updateConditionSymptomsQuery = (id: number, symptoms_description_short: string) => ({
  query: gql`
    ${CONDITION_FIELDS}
    mutation UpdateConditionSymptoms($id: Int!, $symptoms_description_short: String!) {
      update_conditions_by_pk(pk_columns: { id: $id }, _set: { symptoms_description_short: $symptoms_description_short }) {
        ...condition_fields
      }
    }
  `,
  route: 'update_conditions_by_pk',
  variables: { id, symptoms_description_short },
})

export const updateConditionAiNotesQuery = (id: number, ai_notes: string) => ({
  query: gql`
    ${CONDITION_FIELDS}
    mutation UpdateConditionAiNotes($id: Int!, $ai_notes: String!) {
      update_conditions_by_pk(pk_columns: { id: $id }, _set: { ai_notes: $ai_notes }) {
        ...condition_fields
      }
    }
  `,
  route: 'update_conditions_by_pk',
  variables: { id, ai_notes },
})

export const updateConditionActiveLearningDogPriorityQuery = (id: number, active_learning_dog_priority: number) => ({
  query: gql`
    ${CONDITION_FIELDS}
    mutation UpdateConditionActiveLearningDogPriority($id: Int!, $active_learning_dog_priority: Int!) {
      update_conditions_by_pk(pk_columns: { id: $id }, _set: { active_learning_dog_priority: $active_learning_dog_priority }) {
        ...condition_fields
      }
    }
  `,
  route: 'update_conditions_by_pk',
  variables: { id, active_learning_dog_priority },
})

export const updateConditionActiveLearningCatPriorityQuery = (id: number, active_learning_cat_priority: number) => ({
  query: gql`
    ${CONDITION_FIELDS}
    mutation UpdateConditionActiveLearningCatPriority($id: Int!, $active_learning_cat_priority: Int!) {
      update_conditions_by_pk(pk_columns: { id: $id }, _set: { active_learning_cat_priority: $active_learning_cat_priority }) {
        ...condition_fields
      }
    }
  `,
  route: 'update_conditions_by_pk',
  variables: { id, active_learning_cat_priority },
})

export const updateConditionActiveLearningCombinedPriorityQuery = (id: number, active_learning_combined_priority: number) => ({
  query: gql`
    ${CONDITION_FIELDS}
    mutation UpdateConditionActiveLearningCombinedPriority($id: Int!, $active_learning_combined_priority: Int!) {
      update_conditions_by_pk(
        pk_columns: { id: $id }
        _set: { active_learning_combined_priority: $active_learning_combined_priority }
      ) {
        ...condition_fields
      }
    }
  `,
  route: 'update_conditions_by_pk',
  variables: { id, active_learning_combined_priority },
})

export const updateConditionTrainAndDeployDogQuery = (id: number, train_and_deploy_dog: boolean) => ({
  query: gql`
    ${CONDITION_FIELDS}
    mutation UpdateConditionTrainAndDeployDog($id: Int!, $train_and_deploy_dog: Boolean!) {
      update_conditions_by_pk(pk_columns: { id: $id }, _set: { train_and_deploy_dog: $train_and_deploy_dog }) {
        ...condition_fields
      }
    }
  `,
  route: 'update_conditions_by_pk',
  variables: { id, train_and_deploy_dog },
})

export const updateConditionTrainAndDeployCatQuery = (id: number, train_and_deploy_cat: boolean) => ({
  query: gql`
    ${CONDITION_FIELDS}
    mutation UpdateConditionTrainAndDeployCat($id: Int!, $train_and_deploy_cat: Boolean!) {
      update_conditions_by_pk(pk_columns: { id: $id }, _set: { train_and_deploy_cat: $train_and_deploy_cat }) {
        ...condition_fields
      }
    }
  `,
  route: 'update_conditions_by_pk',
  variables: { id, train_and_deploy_cat },
})
