import gql from 'graphql-tag'

export const CONDITION_FIELDS = gql`
  fragment condition_fields on conditions {
    id
    display_name
    ml_name
    specialist_notes
    species
    severity
    symptoms_description_short
    ai_notes
    ml_config
    train_and_deploy_dog
    train_and_deploy_cat
    active_learning_combined_priority
    active_learning_cat_priority
    active_learning_dog_priority
    category {
      id
      order_index
      display_name
      region
    }
  }
`
