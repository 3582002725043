import moment from 'moment'

const isThanksgivingOrWeekend = (now: moment.Moment) => {
  const year = now.year()
  // Calculate the fourth Thursday of November
  const firstDayOfNovember = moment([year, 10, 1]) // November 1st
  const firstThursday =
    firstDayOfNovember.day() <= 4
      ? firstDayOfNovember.day(4) // First Thursday in November
      : firstDayOfNovember.add(1, 'week').day(4) // Next Thursday
  const thanksgiving = firstThursday.clone().add(21, 'days') // Fourth Thursday
  // Check if the given date is Thanksgiving
  if (now.isSame(thanksgiving, 'day')) {
    return true
  }
  // Check if the given date is the day after Thanksgiving
  if (now.isSame(thanksgiving.clone().add(1, 'day'), 'day')) {
    return true
  }
  return false
}

const isHolidaySeasonWeekend = (now: moment.Moment) => {
  const start = moment([now.year(), 10, 22]) // November 22
  const end = moment([now.year() + 1, 0, 3]) // January 3 of the next year
  // Check if today is a Saturday (6) or Sunday (0)
  const isWeekend = now.day() === 6 || now.day() === 0
  // Check if today is within the range
  const isInRange = now.isBetween(start, end, 'day', '[]') // Inclusive range
  return isWeekend && isInRange
}

// Weekends November 22 - Jan3 plus Christmas and Thanksgiving
export const holidayBonusActive = (now = moment()) => {
  const isChristmas = now.month() === 11 && now.date() === 25
  return isChristmas || isThanksgivingOrWeekend(now) || isHolidaySeasonWeekend(now)
}
